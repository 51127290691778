import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: {
		// margin: theme.spacing(1),
		position: 'relative',
		width: '100%',
		// marginRight: 6,
	},
	button: {
		width: '100%',
	},
	buttonSuccess: {
		backgroundColor: green[500],
		'&:hover': {
			backgroundColor: green[700],
		},
	},
	fabProgress: {
		color: green[500],
		position: 'absolute',
		top: -6,
		left: -6,
		zIndex: 1,
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

export default function CircularIntegration(props) {
	const classes = useStyles();
	const [loading, setLoading] = React.useState(props.loading);
	// const [success, setSuccess] = React.useState(false);
	const success = false;
	// const timer = React.useRef();

	const buttonClassname = clsx({
		[classes.buttonSuccess]: success,
		[classes.button]: true,
	});

	// React.useEffect(() => {
	//   return () => {
	//     clearTimeout(timer.current);
	//   };
	// }, []);

	React.useEffect(() => {
		setLoading(props.loading);
	}, [props.loading]);

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<Button
					onClick={props.onClick}
					variant="contained"
					color={props.color}
					className={buttonClassname}
					disabled={loading}
				>
					{props.label}
				</Button>
				{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
			</div>
		</div>
	);
}
