/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios from 'axios';
// import cryptoJS from 'crypto-js';
import configuration from 'react-global-configuration';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import ZLoader from './system/ZLoader';
import ZSplashScreen from './system/ZSplashScreen';
import Login from './Usuarios/Login';
import MainSystem from './Layout/MainSystem';
import AssinarPlano from './Planos/AssinarPlano';
import EsqueciMinhaSenha from './Usuarios/EsqueciMinhaSenha';
import CheckOut from './CheckOut/CheckOut';
import PreCadastrarEC from './Marketplace/PreCadastrarEC';
import PagarVendaDigitada from './VendaDigitada/PagarVendaDigitada';
//import PagarVendaDigitadaAntifraude from './VendaDigitada/PagarVendaDigitadaAntifraudTest';

import { useDispatch, useSelector } from 'react-redux';
import { Isset } from '../utils';
import { checkLogin } from '../store/auth';
import { getConfig, setSystemConfig, getUserParams } from '../store/system';
import ZWarning from './system/ZWarning';

const changeFavicon = src => {
	const link = document.createElement('link');
	const oldLink = document.getElementById('dynamic-favicon');
	link.id = 'dynamic-favicon';
	link.rel = 'shortcut icon';
	link.href = src;
	if (oldLink) {
		document.head.removeChild(oldLink);
	}
	document.head.appendChild(link);
};

const Main = props => {
	const dispatch = useDispatch();
	const logado = useSelector(state => state.auth.logado);
	const usuario = useSelector(state => state.auth.usuario);
	let color = useSelector(state => state.system.color);
	if (usuario.id) {
		const onePay = usuario.principal_estabelecimento === 782 && usuario.estabelecimento.id !== 706;
		if (!color && onePay) {
			color = '#051587';
		}
	}

	const systemConfig = useSelector(state => state.system.config);
	const { location } = useReactRouter();

	const theme = createMuiTheme({
		typography: {
			fontFamily: ['"Exo 2"', 'sans-serif'].join(','),
		},
		palette: {
			primary: {
				main: color,
			},
		},
	});

	const [loadingConfig, setLoadingConfig] = useState(true);
	const [loading, setLoading] = useState(true);
	const [systemOff, setSystemOff] = useState(false);

	useEffect(() => {
		const getSystemConfig = async () => {
			let hostName = window.location.hostname;

			if (hostName === 'localhost' && configuration.get('hostName')) {
				hostName = configuration.get('hostName');
			}
			// console.log(`${configuration.get('apiUrl')}system_config/${hostName}`) // dev mobile
			// const res = await axios.get(`${configuration.get('apiUrl')}system_config/localhost`);

			const res = await axios.get(`${configuration.get('apiUrl')}system_config/${hostName}`);
			if (res.data.success) {
				dispatch(setSystemConfig(res.data.config));
			} else {
				setSystemOff(true);
			}
			setLoadingConfig(false);
		};

		getSystemConfig();
	}, []);

	useEffect(() => {
		if (!loadingConfig) {
			const login = async () => {
				const login = await dispatch(checkLogin());
				if (Isset(() => login.usuario.estabelecimento.id)) {
					await dispatch(getConfig(login.usuario.estabelecimento.id));
				}
				return login;
			};
			login().then(_ => {
				setLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingConfig]);

	useEffect(() => {
		window.document.title = systemConfig.nome;
		changeFavicon(systemConfig.faviconUrl);
	}, [systemConfig]);

	useEffect(() => {
		if (logado) {
			dispatch(getUserParams(usuario));
		}
	}, [logado]);

	if (systemOff) {
		return <ZWarning message="Sistema em manutenção" />;
	}

	if (loadingConfig) {
		return <ZLoader message="Carregando Configurações" />;
	}

	if (loading) {
		return <ZSplashScreen />;
	}

	// var encrypted = cryptoJS.AES.encrypt('15', 'planoZoopZSPay').toString();

	// console.log('encrypted: ' + encrypted);
	// console.log('decripted: ' + cryptoJS.AES.decrypt(encrypted, 'planoZoopZSPay').toString(cryptoJS.enc.Utf8));

	var regAssinar = new RegExp('/plano/[0-9]{0,}/assinar');
	var regAssinarSekron = new RegExp('/plano/[0-9]{0,}/assinar/sekron');
	var urlDeAssinatura = regAssinar.exec(location.pathname) || regAssinarSekron.exec(location.pathname) ? true : false;
	if (urlDeAssinatura) {
		const isSekron = regAssinarSekron.exec(location.pathname) ? true : false;
		let numberPattern = /\d+/g;
		let planoId = location.pathname.match(numberPattern);
		return <AssinarPlano planoId={planoId[0]} isSekron={isSekron} />;
	}

	var regAtualizarCartaoDaAssinatura = new RegExp('/assinatura/atualizar/.*');
	if (regAtualizarCartaoDaAssinatura.exec(location.pathname)) {
		let subscriptionId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		return <AssinarPlano subscriptionId={subscriptionId} />;
	}

	var regPreVenda = new RegExp('/lp/[a-zA-Z0-9]{0,}');
	var regPreVenda2 = new RegExp('/venda/token/[a-zA-Z0-9]{0,}');
	if (regPreVenda.exec(location.pathname) || regPreVenda2.exec(location.pathname)) {
		// let numberPattern = /\d+/g;
		// let token = location.pathname.match(numberPattern);
		let token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		return <PagarVendaDigitada token={token} />;
	}

	// //APAGAR DEPOIS
	// var regPreVenda3 = new RegExp('/lantifraudep/[a-zA-Z0-9]{0,}');
	// var regPreVenda4 = new RegExp('/lantifraudep/[a-zA-Z0-9]{0,}');
	// if (regPreVenda3.exec(location.pathname) || regPreVenda4.exec(location.pathname)) {
	// 	// let numberPattern = /\d+/g;
	// 	// let token = location.pathname.match(numberPattern);
	// 	let token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
	// 	return <PagarVendaDigitadaAntifraude token={token} />;
	// }
	// //APAGAR DEPOIS

	var checkout = new RegExp('/checkout/[a-zA-Z0-9-]{0,}');
	var checkout2 = new RegExp('/checkout/[a-zA-Z0-9-]{0,}');
	if (checkout.exec(location.pathname) || checkout2.exec(location.pathname)) {
		let token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		return <CheckOut token={token} />;
	}

	if (location.pathname === '/checkout/.*') {
		return <CheckOut />;
	}

	// @TODO - remover páginas não utilizadas

	// if (location.pathname === '/cadastrar') {
	// 	return <Cadastrar />;
	// }

	// if (location.pathname === '/cadastrar/estabelecimento') {
	// 	return <CadastrarEstabelecimento />;
	// }

	// if (location.pathname === '/cadastro/sucesso') {
	// 	return <CadastroSuccess />;
	// }

	if (location.pathname === '/esqueci-minha-senha') {
		return <EsqueciMinhaSenha />;
	}

	if (location.pathname === '/pre-cadastro-ec') {
		return <PreCadastrarEC />;
	}

	if (!logado) {
		return <Login />;
	}

	return (
		<div>
			<ThemeProvider theme={theme}>
				<MainSystem />
			</ThemeProvider>
		</div>
	);
};

export default Main;
