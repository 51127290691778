/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ZSaveButton, ZAlert } from '../system';
import {
	Box,
	Container,
	IconButton,
	InputAdornment,
	Link,
	Grid,
	OutlinedInput,
	TextField,
	Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import axios from 'axios';
import validator from 'validator';
import { login, checkLogin } from '../../store/auth';
import { addMessage } from '../../store/alert';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import { Isset } from '../../utils';
import { getConfig } from '../../store/system';
import { useSetRecoilState } from 'recoil';
import { authTokenState } from '../../recoil/atoms';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(12),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '100%',
		// backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Login = props => {
	const { history } = useReactRouter();
	const dispatch = useDispatch();

	const systemConfig = useSelector(store => store.system.config);
	const logado = useSelector(store => store.auth.logado);
	const message = useSelector(store => store.alert);

	const setAuthTokenState = useSetRecoilState(authTokenState);

	useEffect(() => {
		dispatch(checkLogin());
	}, []);

	const classes = useStyles();

	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');
	const [loading, setLoading] = useState(false);

	const [values, setValues] = useState({
		password: '',
		showPassword: false,
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const handleSubmit = () => {
		// e.preventDefault();
		setLoading(true);
		dispatch(addMessage({ type: 'error', message: '' }));

		let valid = true;
		let errorMessages = []; // Array para armazenar mensagens de erro

		if (validator.isEmpty(email)) {
			valid = false;
			errorMessages.push('Preencha o e-mail.'); // Adiciona mensagem ao array
		}
		if (!validator.isEmail(email)) {
			valid = false;
			errorMessages.push('Insira um e-mail válido.'); // Adiciona mensagem ao array
		}
		if (validator.isEmpty(senha)) {
			valid = false;
			errorMessages.push('Preencha a senha.'); // Adiciona mensagem ao array
		}

		// Verifica se houve algum erro
		if (!valid) {
			// Envia todas as mensagens de erro de uma vez
			errorMessages.forEach(message => {
				dispatch(addMessage({ type: 'error', message }));
			});
			setLoading(false); // Define o estado de loading para false
			return; // Sai da função handleSubmit
		}

		axios
			.post(config.get('apiUrl') + 'login', {
				email: email,
				senha: senha,
			})
			.then(res => res.data)
			.then(async data => {
				if (!data.success) {
					dispatch(addMessage({ type: 'error', message: data.message }));
				} else {
					setAuthTokenState(data.usuario.token);
					dispatch(login(data.usuario));

					const check = await dispatch(checkLogin());
					if (Isset(() => check.usuario.estabelecimento.id)) {
						await dispatch(getConfig(check.usuario.estabelecimento.id));
					}
				}
			})
			.catch(err => {
				console.log(err);
				if (err.response && err.response.data && err.response.data.message) {
					dispatch(addMessage({ type: 'error', message: err.response.data.message }));
				} else {
					dispatch(addMessage({ type: 'error', message: 'Erro na requisição.' }));
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (logado) {
		history.push('/');
	}

	return (
		<div style={{ background: systemConfig.backgroundColor }}>
			<Container maxWidth="md" style={{ height: '100vh' }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={6} lg={6} style={{}}>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} style={{ padding: 30 }} align="center">
										<Link href={systemConfig.website} color="inherit">
											<img
												src={systemConfig.logoUrl}
												style={{ maxWidth: '100%' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</Link>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								lg={6}
								style={{ background: 'white', padding: 25, borderRadius: 5 }}
							>
								<form className={classes.form} noValidate>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="E-mail"
										name="email"
										autoComplete="email"
										autoFocus
										onKeyPress={e => (e.key === 'Enter' ? handleSubmit() : null)}
										onChange={e => {
											setEmail(e.target.value.trim());
										}}
										value={email}
									/>
									<OutlinedInput
										style={{ marginTop: '3px', marginBottom: '9px' }}
										name="password"
										//label="Senha *"
										margin="normal"
										required
										fullWidth
										placeholder="Senha *"
										variant="outlined"
										id="password"
										type={values.showPassword ? 'text' : 'password'}
										value={senha}
										onKeyPress={e => (e.key === 'Enter' ? handleSubmit() : null)}
										onChange={e => {
											setSenha(e.target.value);
										}}
										autoComplete="current-password"
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="Mostrar Senha"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{values.showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
									<ZSaveButton
										onClick={e => handleSubmit()}
										color={'primary'}
										align="right"
										// type="submit"
										label={'Login'}
										loading={loading}
									/>
									<Grid container>
										<Grid item xs>
											<Link
												onClick={() => history.push('/esqueci-minha-senha')}
												style={{ cursor: 'pointer' }}
												variant="body2"
											>
												Esqueceu a senha?
											</Link>
										</Grid>
										<Grid item>
											<Link
												onClick={() => history.push('/pre-cadastro-ec')}
												style={{ cursor: 'pointer' }}
												variant="body2"
											>
												{'Cadastre-se'}
											</Link>
										</Grid>
									</Grid>
								</form>

								<Box mt={5}>
									<Typography variant="body2" color="textSecondary" align="center">
										Sistema de Pagamentos
										<br />
										{`© ${systemConfig.nome} ` + moment().format('YYYY')}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{message.message.length > 0 && (
					<Grid item xs={12}>
						<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
					</Grid>
				)}
			</Container>
		</div>
	);
};

export default Login;
