/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Formatter, Mask, Isset } from '../../utils';
import { ZLoader } from '../system';
import { Grid, Button } from '@material-ui/core';
import CalculadoraValorLiquido from '../Calculadoras/ValorLiquido';
import CalculadoraValorBruto from '../Calculadoras/ValorBruto';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	pageTitle: {
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	planos: {
		position: 'relative',
		padding: 14,
		height: 50,
		fontSize: 14,
		fontWeight: 600,
		'& span': {
			fontWeight: 'bold',
			fontSize: 16,
		},
	},
	taxaBoletoDiv: {
		marginTop: 10,
		position: 'relative',
		padding: 10,
		height: 50,
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		float: 'left',
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	bandeirasWrapper: {
		position: 'block',
		marginLeft: 120,
		width: 'calc(100% - 160px)',
		overflowX: 'auto',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		height: 490,
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldBoletoReadOnly: {
		marginLeft: 120,
		height: 30.8,
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	btnCalculadora: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		marginRight: 10,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
}));

const EstabelecimentosTaxas = props => {
	let { estabelecimentoId } = useParams();

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}
	const token = useSelector(store => store.auth.token);
	const userGrupoId = useSelector(store => store.auth.usuario.grupo_id);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const classes = useStyles();

	const [estabelecimentoNome, setEstabelecimentoNome] = useState('');
	const [planoVenda, setPlanoVenda] = useState({});
	const [planoTaxa, setPlanoTaxa] = useState({});
	const [taxaBoleto, setTaxaBoleto] = useState(0);
	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [loading, setLoading] = useState(true);
	const [erroTaxa, setErroTaxa] = useState(false);

	const [showCalculadoraValorLiquido, setShowCalculadoraValorLiquido] = useState(false);
	const [showCalculadoraValorBruto, setShowCalculadoraValorBruto] = useState(false);

	const canViewPlanos = userGrupoId === 1 || operarMarketplace;

	useEffect(() => {
		const getTaxas = async () => {
			setLoading(true);
			setErroTaxa(false);
			const res = await Axios.get(
				`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}/taxaTotal`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setPlanoVenda(res.data.planoVenda);
				setPlanoTaxa(res.data.planoTaxa);
				setTaxaBoleto(res.data.taxaBoleto);
				setTaxas(res.data.taxaTotal);
				setCountBandeiras(Object.keys(res.data.taxaTotal).length);
			} else {
				setErroTaxa(true);
			}
			setLoading(false);
		};

		const getEC = async () => {
			const res = await Axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
                query Estabelecimentos {
                    estabelecimento(id: ${estabelecimentoId}) {
                        nomeFantasia
                    }
                }
            `,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
				const { nomeFantasia: nome } = res.data.data.estabelecimento;
				setEstabelecimentoNome(nome);
			}
		};

		getTaxas();
		getEC();
	}, []);

	if (erroTaxa) {
		return (
			<div>
				<div className={classes.header}>Taxas - {estabelecimentoNome}</div>
				<div className={classes.empty}>Nenhuma Taxa Encontrada.</div>
			</div>
		);
	}

	return (
		<div>
			<Button className={classes.btnCalculadora} onClick={() => setShowCalculadoraValorLiquido(true)}>
				<div>
					<small>Calculadora</small>
				</div>
				<div>Valor Líquido</div>
			</Button>
			<Button className={classes.btnCalculadora} onClick={() => setShowCalculadoraValorBruto(true)}>
				<div>
					<small>Calculadora</small>
				</div>
				<div>Valor Bruto</div>
			</Button>
			<div className={classes.header}>Taxas - {estabelecimentoNome}</div>
			{canViewPlanos && (
				<div className={clsx(classes.tiposPagamentos, classes.planos)}>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							Plano de Venda: <span>{planoVenda.titulo}</span>
						</Grid>
						<Grid item xs={4}>
							Tabela de Markup: <span>{planoTaxa.titulo ? planoTaxa.titulo : 'Nenhum'}</span>
						</Grid>
					</Grid>
				</div>
			)}
			<div className={classes.taxaBoleto}>
				<div className={clsx(classes.tiposPagamentos, classes.taxaBoletoDiv)}>
					<div className={classes.tipoPagamentoBoleto}>Boleto</div>
					<div className={classes.taxaFieldBoletoReadOnly}>{Formatter.Real(taxaBoleto)}</div>
				</div>
			</div>
			<div style={{ marginTop: 10 }}>
				<Grid className={classes.tiposPagamentos}>
					<div className={classes.tipoPagamento}>Crédito 1x</div>
					<div className={classes.tipoPagamento}>Crédito 2x</div>
					<div className={classes.tipoPagamento}>Crédito 3x</div>
					<div className={classes.tipoPagamento}>Crédito 4x</div>
					<div className={classes.tipoPagamento}>Crédito 5x</div>
					<div className={classes.tipoPagamento}>Crédito 6x</div>
					<div className={classes.tipoPagamento}>Crédito 7x</div>
					<div className={classes.tipoPagamento}>Crédito 8x</div>
					<div className={classes.tipoPagamento}>Crédito 9x</div>
					<div className={classes.tipoPagamento}>Crédito 10x</div>
					<div className={classes.tipoPagamento}>Crédito 11x</div>
					<div className={classes.tipoPagamento}>Crédito 12x</div>
					<div className={classes.tipoPagamento}>Débito</div>
				</Grid>

				{loading ? (
					<ZLoader height={300} />
				) : (
					<div className={classes.bandeirasWrapper}>
						<div style={{ width: (countBandeiras + 1) * 110 }}>
							{Object.keys(taxas).map(bandeiraId => (
								<div key={`Bandeira-${bandeiraId}`} className={classes.bandeira}>
									<div
										className={clsx(classes.title, {
											[classes.titleLong]: taxas[bandeiraId].titulo.length > 9,
										})}
									>
										{taxas[bandeiraId].titulo}
									</div>
									<div className={classes.fields}>
										{Object.keys(taxas[bandeiraId].valores).map(tipoPagamentoId => (
											<div
												key={`${bandeiraId}${tipoPagamentoId}`}
												className={classes.tipoPagamentoFields}
											>
												{Object.keys(taxas[bandeiraId].valores[tipoPagamentoId].valores).map(
													parcela => (
														<div
															key={`${bandeiraId}-${parcela}`}
															className={classes.taxaFieldReadOnly}
														>
															{Mask.Porcentagem(
																taxas[bandeiraId].valores[tipoPagamentoId].valores[
																	parcela
																]
															)}
															%
														</div>
													)
												)}
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>

			{showCalculadoraValorLiquido && (
				<CalculadoraValorLiquido
					open={showCalculadoraValorLiquido}
					estabelecimentoId={estabelecimentoId}
					onClose={() => setShowCalculadoraValorLiquido(false)}
				/>
			)}

			{showCalculadoraValorBruto && (
				<CalculadoraValorBruto
					open={showCalculadoraValorBruto}
					estabelecimentoId={estabelecimentoId}
					onClose={() => setShowCalculadoraValorBruto(false)}
				/>
			)}
		</div>
	);
};

export default EstabelecimentosTaxas;
